const Autodesk = window.Autodesk;
const THREE = window.THREE;

export default class BIMAcademyColourObjects extends Autodesk.Viewing
    .Extension {
    constructor(viewer, options = {}) {
        super(viewer, options);
        this.viewer = viewer;
        this.options = options;
        this.roomData = options.vue.getRooms;
        this.getRoomTemperatureColourByDbId =
            options.vue.getRoomTemperatureColourByDbId;
    }

    static get ExtensionId() {
        return "BIMAcademy.ColourObjects";
    }

    load() {
        return true;
    }

    async colourAll() {
        for (let i = 0; i < this.roomData.roomIds.length; i++) {
            const roomId = this.roomData.roomIds[i];
            this.colourSelected(roomId);
        }
    }

    async colourSelected(roomId) {
        const colour = await this.getRoomTemperatureColourByDbId(roomId);
        this.setObjectColour(roomId, colour.roomColour);
    }

    setObjectColour(nodeId, colourHex) {
        //this.clearColours();

        const temperatureColour = new THREE.Color(colourHex);
        const temperatureColourVec4 = new THREE.Vector4(
            temperatureColour.r,
            temperatureColour.g,
            temperatureColour.b,
            1.0
        );

        this.viewer.setThemingColor(nodeId, temperatureColourVec4);
        this.viewer.impl.invalidate(true);
    }

    clearColours() {
        this.viewer.clearThemingColors();
    }

    unload() {
        this.clearColours();
        return true;
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension(
    "BIMAcademy.ColourObjects",
    BIMAcademyColourObjects
);
